@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'extensions/bourbon/bourbon';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

//
// Fonts
//

@font-face {
    font-family: 'Futura TEE';
    src: url('../fonts/ufonts.com_futuratee.eot');
    src: url('../fonts/ufonts.com_futuratee.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ufonts.com_futuratee.woff') format('woff'),
         url('../fonts/ufonts.com_futuratee.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@mixin futuratee {
  font-family: 'Futura TEE', 'Futura', 'Helvetica';
}

@font-face {
    font-family: 'Futura TEE Light';
    src: url('../fonts/ufonts.com_futurateelig.eot');
    src: url('../fonts/ufonts.com_futurateelig.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ufonts.com_futurateelig.woff') format('woff'),
         url('../fonts/ufonts.com_futurateelig.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@mixin futurateelight {
  font-family: 'Futura TEE Light', 'Futura', 'Helvetica';
}

BODY {
  @include futuratee;
  
  overflow-y: scroll;
  
  &[id='index.php'] {
    background-color: $white;
    background-image: url('../img/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hidden {
  display: none;
}

* {
  outline: 0 !important;
}

// Header

#header {
  background-color: $grey;
  height: 50px;
  border-bottom: solid $brown 12px;
  margin-top: 115px;
  
  .menupart {
    width: 50%;
    float: left;
  }
}

// UX Cards

#controls {
  background-color: $red;
  border-top: solid $grey 12px;
  color: $white;
  
  > DIV {
    height: 40px;
    padding-top: rem-calc(7);
  
    > DIV {
      color: $lightRed;
      height: 20px;
      float: left;
      
      &.bar-item {
        @include transition(border-color 0.5s ease-out, color 0.5s ease-out);
      
        cursor: pointer;
        border-bottom: 1px solid $red;
    
        &.selected, &:hover {
          @include transition(border-color 0.5s ease-in, color 0.5s ease-in);
          
          border-bottom-color: $white;
          color: $white;
        }
      }
    }
  }
  
  #filters {
    > DIV {
      margin-left: rem-calc(20);
    }
  }
  
  #sorting {
    #search {
      width: 200px;
      height: 30px;
      margin: rem-calc(-2) rem-calc(10) 0 rem-calc(30);
      float: right;
      background: rgba(242, 226, 229, 0.3);
      color: $white;
      border: none;
      border-bottom: 1px solid $white;
      box-shadow: none;
      
      @include placeholder {
        color: $white;
      }
    }
    
    > DIV {
      margin-right: rem-calc(20);
      float: right;
    }
  }
}

#uxcardssection {
  background-color: $white;
  padding: rem-calc(30) 0;
}

#uxcards {
  margin: 0 auto;
  
  .uxcardwrapper {
    padding: 0px rem-calc(5 10);
    width: 154px;
    height: 256px;
    float: left;
    
    .uxcard {
      border: 1px solid $grey;
      width: 144px;
      height: 246px;
      position: relative;
      cursor: pointer;
      outline: 0 !important;
      
      IMG {
        width: 100%;
        height: 100%;
      }
      
      .label {
        background: rgba(93, 94, 94, 0.77);
        position: absolute;
        bottom: 0px;
        width: 143px;
        font-size: rem-calc(14);
        color: $white;
        line-height: rem-calc(17);
        padding: rem-calc(10);
        white-space: inherit;
      }
    }
  }
}

#modals {
  .reveal {
    overflow-y: visible !important;
    
    BUTTON {
      @include transition(color 0.5s ease-out);
      
      color: $red;
      
      &:hover {
        @include transition(color 0.5s ease-in);
        
        color: $white;
      }
    }
    
    .close-button {
      top: rem-calc(-10);
      right: rem-calc(-30);
      font-size: rem-calc(37);
    }
    
    .previous-button, .next-button {
      position: absolute;
      top: 44%;
      font-size: rem-calc(47);
    }
    
    .previous-button {
      left: rem-calc(-51);
    }
    
    .next-button {
      right: rem-calc(-51);
    }
    
    IMG {
      width: 40%;
      float: left;
    }
    
    .content {
      float: left;
      width: 60%;
      padding-left: rem-calc(20);
      padding-right: rem-calc(5);
      
      .name {
        @include futurateelight;
        
        font-size: rem-calc(24);
        padding-bottom: rem-calc(10);
        color: $red;
      }
      
      .description {
        font-size: rem-calc(16);
      }
      
      .link {
        position: absolute;
        bottom: rem-calc(12);
        right: rem-calc(24);
        font-size: rem-calc(13);
      }
      
      .date {
        position: absolute;
        bottom: rem-calc(12);
        font-size: rem-calc(13);
      }
    }
    
    .categories {
      position: absolute;
      top: 100%;
      width: 200%;
      left: 0px;
      
      .category {
        @include transition(background-color 0.5s ease-out, color 0.5s ease-out);
        
        float: left;
        background-color: $red;
        margin: rem-calc(10) rem-calc(10) 0 0;
        padding: rem-calc(10 20);
        color: $white;
        cursor: pointer;
        
        &:hover {
          @include transition(background-color 0.5s ease-in, color 0.5s ease-in);
          
          color: $red;
          background-color: $white;
        }
      }
    }
  }
}

.titlesection {
  text-align: center;
  color: $grey;
  
  .title {
    @include futurateelight;
    
    background-color: rgba($red, 0.6);
    font-size: rem-calc(50);
    color: $white;
    padding: rem-calc(40);
    width: 60%;
    margin: rem-calc(30) auto;
  }
  
  .subtitle {
    background-color: rgba($white, 0.95);
    
    font-size: rem-calc(18);
    width: 90%;
    margin: rem-calc(47) auto;
    color: $black;
    padding: rem-calc(30);
    
    .section {
      padding-top: 10px;
      font-size: rem-calc(14);
    }
  }
}

.message {
  border: 2px solid rgba($red, 0.4);
  background: rgba($red, 0.2);
  color: $black;
  font-size: rem-calc(14);
  text-align: center;
  padding: rem-calc(10);
  margin-bottom: rem-calc(15);
}

#footer {
  background-color: $grey;
  height: 40px;
  border-top: 3px solid $red;
  text-align: center;
  color: $white;
  
  .center {
    display: inline-block;
    margin: 0 auto;
    
    .ccimage, .text {
      float: left;
    }
    
    .ccimage {
      width: 80px;
      padding-top: 5px;
      margin-right: 10px;
    }
    
    .text {
      padding-top: rem-calc(7);
      
      A {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}