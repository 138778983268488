//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Abide
//   2. Accordion
//   3. Badge
//   4. Breadcrumbs
//   5. Breakpoints
//   6. Button
//   7. Button Group
//   8. Callout
//   9. Close Button
//  10. Dropdown
//  11. Flex Video
//  12. Forms
//  13. Global
//  14. The Grid
//  15. Label
//  16. Media Object
//  17. Menu
//  18. Off-canvas
//  19. Orbit
//  20. Pagination
//  21. Progress Bar
//  22. Reveal
//  23. Slider
//  24. Switch
//  25. Table
//  26. Tabs
//  27. Thumbnail
//  28. Tooltip
//  29. Top Bar
//  30. Base Typography
//  31. Typography Helpers

//
// Colors
//

$grey: #979797;
$white: #FFFBF7;
$red: #E76D83;
$lightRed: #F2E2E5;
$brown: #975200;
$black: #2D2E2E;

// Abide
// -----

// $abide-inputs: true;
// $abide-labels: true;
// $input-background-invalid: $alert-color;
// $form-label-color-invalid: $alert-color;
// $input-error-color: $alert-color;
// $input-error-font-size: rem-calc(12);
// $input-error-font-weight: $global-weight-bold;

// Accordion
// ---------

// $accordion-background: $white;
// $accordion-plusminus: true;
// $accordion-item-color: foreground($accordion-background, $primary-color);
// $accordion-item-background-hover: $light-gray;
// $accordion-item-padding: 1.25rem 1rem;
// $accordion-content-background: $white;
// $accordion-content-border: 1px solid $light-gray;
// $accordion-content-color: foreground($accordion-background, $primary-color);
// $accordion-content-padding: 1rem;

// Badge
// -----

// $badge-background: $primary-color;
// $badge-color: foreground($badge-background);
// $badge-padding: 0.3em;
// $badge-minwidth: 2.1em;
// $badge-font-size: 0.6rem;

// Breadcrumbs
// -----------

// $breadcrumbs-margin: 0 0 $global-margin 0;
// $breadcrumbs-item-font-size: rem-calc(11);
// $breadcrumbs-item-color: $primary-color;
// $breadcrumbs-item-color-current: $black;
// $breadcrumbs-item-color-disabled: $medium-gray;
// $breadcrumbs-item-margin: 0.75rem;
// $breadcrumbs-item-uppercase: true;
// $breadcrumbs-item-slash: true;

// Breakpoints
// -----------

// $breakpoints: (
//   small: 0,
//   medium: 512px,
//   large: 1024px,
//   xlarge: 1200px,
//   xxlarge: 1440px,
// );
// $breakpoint-classes: (small medium large);

// Button
// ------

// $button-padding: 0.85em 1em;
// $button-margin: 0 $global-margin $global-margin 0;
// $button-fill: solid;
// $button-background: $primary-color;
// $button-background-hover: scale-color($button-background, $lightness: -15%);
// $button-font-color: #fff;
// $button-font-color-alt: #000;
// $button-sizes: (
//   tiny: 0.6rem,
//   small: 0.75rem,
//   default: 0.9rem,
//   large: 1.25rem,
// );
// $button-opacity-disabled: 0.25;

// Button Group
// ------------

// $buttongroup-margin: 1rem;
// $buttongroup-spacing: 1px;
// $buttongroup-child-selector: '.button';
// $buttongroup-expand-max: 6;

// Callout
// -------

// $callout-background: $white;
// $callout-background-fade: 85%;
// $callout-border: 1px solid rgba($black, 0.25);
// $callout-margin: 0 0 1rem 0;
// $callout-padding: 1rem;
// $callout-font-color: $body-font-color;
// $callout-font-color-alt: $body-background;
// $callout-link-tint: 30%;

// Close Button
// ------------

// $closebutton-position: $global-right top;
// $closebutton-offset-horizontal: 1rem;
// $closebutton-offset-vertical: 0.5rem;
// $closebutton-size: 2em;
// $closebutton-lineheight: 1;
// $closebutton-color: $dark-gray;
// $closebutton-color-hover: $black;

// Dropdown
// --------

// $dropdown-padding: 1rem;
// $dropdown-border: 1px solid $medium-gray;
// $dropdown-font-size: 16rem;
// $dropdown-width: 300px;
// $dropdown-sizes: (
//   tiny: 100px,
//   small: 200px,
//   large: 400px,
// );

// Flex Video
// ----------

// $flexvideo-padding-top: rem-calc(25);
// $flexvideo-margin-bottom: rem-calc(16);
// $flexvideo-ratio: 4 by 3;
// $flexvideo-ratio-widescreen: 16 by 9;

// Forms
// -----

// $fieldset-border: 1px solid $medium-gray;
// $fieldset-padding: rem-calc(20);
// $fieldset-margin: rem-calc(18 0);
// $legend-padding: rem-calc(0 3);
// $form-spacing: rem-calc(16);
// $helptext-color: #333;
// $helptext-font-size: rem-calc(13);
// $helptext-font-style: italic;
// $input-prefix-color: $black;
// $input-prefix-background: $light-gray;
// $input-prefix-border: 1px solid $medium-gray;
// $input-prefix-padding: 1rem;
// $form-label-color: $black;
// $form-label-font-size: rem-calc(14);
// $form-label-font-weight: $global-weight-normal;
// $form-label-line-height: 1.8;
// $select-background: #fafafa;
// $select-triangle-color: #333;
// $input-color: $dark-gray;
// $input-font-family: inherit;
// $input-font-size: rem-calc(16);
// $input-background: $white;
// $input-background-focus: $white;
// $input-background-disabled: $light-gray;
// $input-border: 1px solid $medium-gray;
// $input-border-focus: 1px solid $dark-gray;
// $input-shadow: inset 0 1px 2px rgba($black, 0.1);
// $input-shadow-focus: 0 0 5px $medium-gray;
// $input-cursor-disabled: default;
// $input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
// $input-number-spinners: true;

// Global
// ------

$global-width: 100%;
// $global-font-size: 100%;
// $global-lineheight: 1.5;
// $primary-color: #2199e8;
// $secondary-color: #777;
// $success-color: #3adb76;
// $warning-color: #ffae00;
// $alert-color: #ec5840;
// $light-gray: #f3f3f3;
// $medium-gray: #cacaca;
// $dark-gray: #8a8a8a;
// $black: #0a0a0a;
// $white: #fefefe;
// $body-background: $white;
// $body-font-color: $black;
// $body-font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
// $body-antialiased: true;
// $text-direction: ltr;
// $global-margin: 1rem;
// $global-padding: 1rem;
// $global-margin: 1rem;
// $global-weight-normal: normal;
// $global-weight-bold: bold;
// $global-radius: 3px;
// $global-namespace: false;
// $global-text-direction: ltr;
// $rem-base: 16px;

// The Grid
// --------

$grid-row-width: $global-width;
// $grid-column-count: 12;
// $grid-column-gutter: 1.875rem / 2;

// Label
// -----

// $label-background: $primary-color;
// $label-color: foreground($label-background);
// $label-font-size: 0.8rem;
// $label-padding: 0.33333rem 0.5rem;

// Media Object
// ------------

// $mediaobject-margin-bottom: $global-margin;
// $mediaobject-section-padding: $global-padding;
// $mediaobject-image-width-stacked: 100%;

// Menu
// ----

// $menu-margin: 0;
// $menu-margin-nested: 1rem;
// $menu-item-padding: 0.7rem 1rem;
// $menu-icon-spacing: 0.25rem;
// $menu-expand-max: 6;

// Off-canvas
// ----------

// $offcanvas-size: 250px;
// $offcanvas-background: $white;
// $offcanvas-zindex: -1;
// $offcanvas-transition-length: 0.5s;
// $offcanvas-transition-timing: ease;
// $offcanvas-exit-background: rgba($white, 0.25);
// $maincontent-class: 'main-content';
// $maincontent-shadow: 0 0 10px rgba($black, 0.5);

// Orbit
// -----

// $orbit-bullet-background: $medium-gray;
// $orbit-bullet-background-active: $dark-gray;
// $orbit-bullet-diameter: 1.2rem;
// $orbit-bullet-margin: 0.1rem;
// $orbit-bullet-margin-top: 0.8rem;
// $orbit-bullet-margin-bottom: 0.8rem;
// $orbit-caption-background: rgba($black, 0.5);
// $orbit-caption-padding: 1rem;
// $orbit-control-background-hover: rgba($black, 0.5);
// $orbit-control-padding: 1rem;
// $orbit-control-zindex: 10;

// Pagination
// ----------

// $pagination-font-size: rem-calc(14);
// $pagination-margin-bottom: $global-margin;
// $pagination-item-color: $black;
// $pagination-item-padding: rem-calc(3 10);
// $pagination-item-spacing: rem-calc(1);
// $pagination-item-background-hover: $light-gray;
// $pagination-item-background-current: $primary-color;
// $pagination-item-color-current: foreground($pagination-item-background-current);
// $pagination-item-color-disabled: $medium-gray;
// $pagination-ellipsis-color: $black;
// $pagination-mobile-items: false;
// $pagination-arrows: true;

// Progress Bar
// ------------

// $progress-height: 1rem;
// $progress-background: $medium-gray;
// $progress-margin-bottom: $global-margin;
// $progress-meter-background: $primary-color;

// Reveal
// ------

// $reveal-background: $white;
$reveal-width: 500px;
$reveal-max-width: $reveal-width;
// $reveal-offset: rem-calc(100);
// $reveal-padding: $global-padding;
$reveal-border: 1px solid $red;
// $reveal-zindex: 1005;
$reveal-overlay-background: rgba($grey, 0.7);

// Slider
// ------

// $slider-height: 0.5rem;
// $slider-width-vertical: $slider-height;
// $slider-background: $light-gray;
// $slider-fill-background: $medium-gray;
// $slider-handle-height: 1.4rem;
// $slider-handle-width: 1.4rem;
// $slider-handle-background: $primary-color;
// $slider-opacity-disabled: 0.25;
// $slider-transition: all 0.2s ease-in-out;

// Switch
// ------

// $switch-background: $medium-gray;
// $switch-background-active: $primary-color;
// $switch-height: 2rem;
// $switch-height-tiny: 1.5rem;
// $switch-height-small: 1.75rem;
// $switch-height-large: 2.5rem;
// $switch-radius: 0;
// $switch-margin: $global-margin;
// $switch-paddle-background: $white;
// $switch-paddle-offset: 0.25rem;
// $switch-paddle-radius: 0;
// $switch-paddle-transition: all 0.25s ease-out;

// Table
// -----

// $table-background: $white;
// $table-color-scale: 5%;
// $table-border: 1px solid smart-scale($table-background, $table-color-scale);
// $table-padding: rem-calc(8 10 10);
// $table-hover-scale: 2%;
// $table-row-hover: darken($table-background, $table-hover-scale);
// $table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
// $table-striped-background: smart-scale($table-background, $table-color-scale);
// $table-stripe: even;
// $table-head-background: smart-scale($table-background, $table-color-scale / 2);
// $table-foot-background: smart-scale($table-background, $table-color-scale);
// $table-head-font-color: $body-font-color;
// $show-header-for-stacked: false;

// Tabs
// ----

// $tab-margin: 0;
// $tab-background: $white;
// $tab-background-active: $light-gray;
// $tab-border: $light-gray;
// $tab-item-color: foreground($tab-background, $primary-color);
// $tab-item-background-hover: $white;
// $tab-item-padding: 1.25rem 1.5rem;
// $tab-expand-max: 6;
// $tab-content-background: $white;
// $tab-content-border: $light-gray;
// $tab-content-color: foreground($tab-background, $primary-color);
// $tab-content-padding: 1rem;

// Thumbnail
// ---------

// $thumbnail-border: solid 4px $white;
// $thumbnail-margin-bottom: $global-margin;
// $thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
// $thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
// $thumbnail-transition: box-shadow 200ms ease-out;

// Tooltip
// -------

// $tooltip-background-color: $black;
// $tooltip-padding: 0.75rem;
// $tooltip-font-size: $small-font-size;
// $tooltip-pip-width: 0.75rem;
// $tooltip-pip-height: $tooltip-pip-width * 0.866;
// $tooltip-pip-offset: 1.25rem;

// Top Bar
// -------

// $topbar-padding: 0.5rem;
// $topbar-background: #eee;
// $topbar-link-color: #fff;
// $topbar-input-width: 200px;

// Base Typography
// ---------------

// $header-font-family: $body-font-family;
// $header-font-weight: $global-weight-normal;
// $header-font-style: normal;
// $font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;
// $header-sizes: (
//   small: (
//     'h1': 24,
//     'h2': 20,
//     'h3': 19,
//     'h4': 18,
//     'h5': 17,
//     'h6': 16,
//   ),
//   medium: (
//     'h1': 48,
//     'h2': 40,
//     'h3': 31,
//     'h4': 25,
//     'h5': 20,
//     'h6': 16,
//   ),
// );
// $header-color: inherit;
// $header-lineheight: 1.4;
// $header-margin-bottom: 0.5rem;
// $header-text-rendering: optimizeLegibility;
// $small-font-size: 80%;
// $header-small-font-color: $medium-gray;
// $paragraph-lineheight: 1.6;
// $paragraph-margin-bottom: 1rem;
// $paragraph-text-rendering: optimizeLegibility;
// $code-color: $black;
// $code-font-family: $font-family-monospace;
// $code-font-weight: $global-weight-normal;
// $code-background: $light-gray;
// $code-border: 1px solid $medium-gray;
// $code-padding: rem-calc(2 5 1);
// $anchor-color: $primary-color;
// $anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
// $anchor-text-decoration: none;
// $anchor-text-decoration-hover: none;
// $hr-width: $global-width;
// $hr-border: 1px solid $medium-gray;
// $hr-margin: rem-calc(20) 0;
// $list-lineheight: $paragraph-lineheight;
// $list-margin-bottom: $paragraph-margin-bottom;
// $list-style-type: disc;
// $list-style-position: outside;
// $list-side-margin: 1.25rem;
// $list-nested-side-margin: 1.25rem;
// $defnlist-margin-bottom: 1rem;
// $defnlist-term-weight: $global-weight-bold;
// $defnlist-term-margin-bottom: 0.3rem;
// $blockquote-color: $dark-gray;
// $blockquote-padding: rem-calc(9 20 0 19);
// $blockquote-border: 1px solid $medium-gray;
// $cite-font-size: rem-calc(13);
// $cite-color: $dark-gray;
// $keystroke-font: $font-family-monospace;
// $keystroke-color: $black;
// $keystroke-background: $light-gray;
// $keystroke-padding: rem-calc(2 4 0);
// $keystroke-radius: $global-radius;
// $abbr-underline: 1px dotted $black;

// Typography Helpers
// ------------------

// $lead-font-size: $global-font-size * 1.25;
// $lead-lineheight: 1.6;
// $subheader-lineheight: 1.4;
// $subheader-color: $dark-gray;
// $subheader-font-weight: $global-weight-normal;
// $subheader-margin-top: 0.2rem;
// $subheader-margin-bottom: 0.5rem;
// $stat-font-size: 2.5rem;
